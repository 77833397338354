import React, { useState, useEffect } from 'react';

import Link from '../../Link';
import { HeaderType } from '@/app/(landing)/lib/types/header';
import { routes } from '@/app/(landing)/lib/routes';
import Image from '../../Image';
import { cn } from '@/utils/cn';
import MobileToggleCloseIcon from '@/app/(landing)/components/icons/MobileToggleCloseIcon';
import MobileToggle from '@/app/(landing)/components/icons/MobileToggle';
import { getUserID } from '@/utils/account/user';

export interface MobileViewProps {
  block: HeaderType;
  handleToggle: () => void;
  toggle: boolean;
  path: string;
}
const MobileView: React.FC<MobileViewProps> = ({
  block,
  handleToggle,
  toggle
}) => {
  const { logo, navItems, author } = block || {};
  const [userID, setUserID] = useState<string | null>(null);

  useEffect(() => {
    const fetchUserID = async () => {
      const id = await getUserID();
      setUserID(id);
    };
    fetchUserID();
  }, []);

  const renderLink = () => {
    if (userID) {
      return (
        <Link
          to="/dashboard"
          className="text-base em:text-lg hover:text-brand-Orange transition-colors duration-300 text-brand-Black1"
        >
          Dashboard
        </Link>
      );
    } else {
      return (
        <Link
          to="/signup"
          className="text-base em:text-lg hover:text-brand-Orange transition-colors duration-300 text-brand-Black1"
        >
          Sign Up
        </Link>
      );
    }
  };

  return (
    <div className=" flex lg:hidden justify-between items-center gap-4 w-full ">
      {logo && (
        <Link to={routes.home()}>
          <Image src={logo} alt={logo?.alt} priority />
        </Link>
      )}
      <div className="flex items-center gap-4">
        {/* {author && (
          <div>
            <Image
              src={author?.image}
              alt={author?.image?.alt}
              className=" object-cover cursor-pointer"
            />
          </div>
        )} */}
        <div onClick={handleToggle} className={cn(toggle ? 'relative ' : '')}>
          {toggle ? (
            <MobileToggleCloseIcon className="w-[25px] h-[25px] cursor-pointer" />
          ) : (
            <MobileToggle className="cursor-pointer w-[25px] h-[25px]" />
          )}
        </div>
      </div>

      {toggle && (
        <div className="absolute bg-black/20 inset-0 h-[150vh] -z-10 top-[65px]"></div>
      )}
      {toggle && (
        <div className="absolute left-0 right-0 top-[65px] z-10  h-[100vh] overflow-y-auto">
          <div>
            <div className="absolute left-0 right-0 top-[0px] py-[30px] px-5 bg-white w-full overflow-y-auto z-20 shadow-md">
              {navItems && (
                <div className="flex flex-col items-center gap-4">
                  {navItems?.map((item, index) => {
                    const { link, title } = item || {};
                    return (
                      <div
                        key={index}
                        className="w-full text-center border-b-[1px] pb-4 border-brand-SlateGray"
                      >
                        <Link
                          to={link}
                          className="text-base em:text-lg hover:text-brand-Orange transition-colors duration-300 text-brand-Black1"
                        >
                          {title}
                        </Link>
                      </div>
                    );
                  })}
                  <div className="w-full text-center border-b-[1px] pb-4 border-brand-SlateGray">
                    {renderLink()}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileView;
