import React, { useState, useEffect } from 'react';

import Link from '../../Link';
import { HeaderType } from '@/app/(landing)/lib/types/header';
import { routes } from '@/app/(landing)/lib/routes';
import Image from '../../Image';
import clsx from 'clsx';
import { Button } from '@/components/ui/button';
import { getUserID } from '@/utils/account/user';

export interface DeskTopViewProps {
  block: HeaderType;
  path: string;
}

const DeskTopView: React.FC<DeskTopViewProps> = ({ block, path }) => {
  const { logo, author, navItems } = block || {};
  const [userID, setUserID] = useState<string | null>(null);

  useEffect(() => {
    const fetchUserID = async () => {
      const id = await getUserID();
      setUserID(id);
    };
    fetchUserID();
  }, []);

  const renderButton = () => {
    if (userID) {
      return (
        <Button
          variant="orange"
          onClick={() => (window.location.href = '/dashboard')}
        >
          Dashboard
        </Button>
      );
    } else {
      return (
        <Button
          variant="orange"
          onClick={() => (window.location.href = '/login')}
        >
          Sign In
        </Button>
      );
    }
  };

  return (
    <div className="hidden lg:flex justify-between items-center">
      <div>
        {logo && (
          <Link to={routes.home()}>
            <Image
              src={logo}
              alt={logo?.alt}
              priority
              className="w-full max-w-[150px]  h-[26px]"
            />
          </Link>
        )}
      </div>
      <div className="flex mx-auto pr-10">
        {navItems && (
          <div className="flex gap-[61px] items-center">
            {navItems?.map((item, index) => {
              const { link, title } = item || {};
              const isActive = path?.includes(link) ?? path === link;
              return (
                <div key={index}>
                  <Link
                    to={link}
                    className={clsx(
                      "text-sm hover:text-brand-Orange BasicTransition navlink relative after:absolute after:content-[''] after:top-[110%] after:left-0 after:w-full after:h-[3px] after:bg-brand-Orange ",

                      isActive ? 'text-brand-Orange ' : ''
                    )}
                  >
                    {title}
                  </Link>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div>{renderButton()}</div>
    </div>
  );
};

export default DeskTopView;
